// margin Style

.margin-top-5 {
    margin-top: 5px;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-top-25 {
    margin-top: 25px;
}

.margin-top-30 {
    margin-top: 25px;
}

.margin-bottom-5 {
    margin-bottom: 5px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}

.margin-bottom-30 {
    margin-bottom: 25px;
}

.margin-left-5 {
    margin-left: 5px;
}

.margin-left-10 {
    margin-left: 10px;
}

.margin-left-15 {
    margin-left: 15px;
}

.margin-left-20 {
    margin-left: 20px;
}

.margin-left-25 {
    margin-left: 25px;
}

.margin-left-30 {
    margin-left: 25px;
}

.margin-right-5 {
    margin-right: 5px;
}

.margin-right-10 {
    margin-right: 10px;
}

.margin-right-15 {
    margin-right: 15px;
}

.margin-right-20 {
    margin-right: 20px;
}

.margin-right-25 {
    margin-right: 25px;
}

.margin-right-30 {
    margin-right: 25px;
}

.no-margin-left {
    margin-left: 0 !important;
}

.no-margin-right {
    margin-right: 0 !important;
}

.no-margin-top {
    margin-top: 0 !important;
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

// Padding Style

.padding-top-5 {
    padding-top: 5px;
}

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-25 {
    padding-top: 25px;
}

.padding-top-30 {
    padding-top: 25px;
}

.padding-bottom-5 {
    padding-bottom: 5px;
}

.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-25 {
    padding-bottom: 25px;
}

.padding-bottom-30 {
    padding-bottom: 25px;
}

.padding-right-5 {
    padding-right: 5px;
}

.padding-right-10 {
    padding-right: 10px;
}

.padding-right-15 {
    padding-right: 15px;
}

.padding-right-20 {
    padding-right: 20px;
}

.padding-right-25 {
    padding-right: 25px;
}

.padding-right-30 {
    padding-right: 25px;
}

.padding-left-5 {
    padding-left: 5px;
}

.padding-left-10 {
    padding-left: 10px;
}

.padding-left-15 {
    padding-left: 15px;
}

.padding-left-20 {
    padding-left: 20px;
}

.padding-left-25 {
    padding-left: 25px;
}

.padding-left-30 {
    padding-left: 25px;
}

.no-padding-left {
    padding-left: 0 !important;
}

.no-padding-right {
    padding-right: 0 !important;
}

.no-padding-top {
    padding-top: 0 !important;
}

.no-padding-bottom {
    padding-bottom: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

// align Style

.align-center {
    display: flex;
    align-items: center;
}

// Text align Style

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

// Text Transform

.text-lowercase {
    text-transform: lowercase;
}

.text-capitalize {
    text-transform: capitalize;
}

.text-uppercase {
    text-transform: uppercase;
}

// Font Weight

.font-weight-light {
    font-weight: 300;
}

.font-weight-medium {
    font-weight: 500;
}

.font-weight-bold {
    font-weight: 900;
}

// default Width & Float

.default-float-width {
    float: left;
    width: 100%;
}

// Cursor Style

.cursor-pointer {
    cursor: pointer;
}

// Custom Width

.width-3 {
    width: 3%;
}

.width-5 {
    width: 5%;
}
.width-8 {
    width: 8%;
}
.width-10 {
    width: 10%;
}

.width-12 {
    width: 12%;
}
.width-12-5 {
    width: 12.5%;
}
.width-13-5 {
    width: 13.5%;
}

.width-14 {
    width: 14%;
}

.width-15 {
    width: 15%;
}

.width-20 {
    width: 20%;
}

.width-30 {
    width: 20%;
}

.width-40 {
    width: 40%;
}

.width-50 {
    width: 50%;
}

.width-60 {
    width: 60%;
}

.width-70 {
    width: 70%;
}

.width-80 {
    width: 80%;
}

.width-90 {
    width: 90%;
}

.border-bottom-blue {
    border-bottom: 2px solid #36f !important;
}

.no-top-border-header {
    th {
        border-top: 0px !important;
    }
}

.func-table-view {
    // height: 100% !important;
}
.func-table-body-view-mode {
    height: calc(100% - 36px) !important;
    td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    td:hover {
        text-overflow: clip;
        white-space: normal;
        word-break: break-all;
    }
}
